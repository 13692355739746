@charset 'UTF-8';

.sidebar--right {
    @include translateX(100%);

    position: absolute;
    z-index: 11;
    right: 0;
    width: 400px;
    height: 100%;
    padding: 0;

    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    &.move--left {
        transition: width 0.25s 0s ease-in, z-index 0.25s 0s step-end;
    }

    &.sidebar--right--expanded {
        @include box-shadow(0 8px 24px alpha-color($black, 0.24));

        z-index: 13;

        .sidebar-right__body {
            overflow: hidden;
        }

        &.move--left {
            transition: width 0.25s 0s ease-in, z-index 0.15s 0.1s step-start;
        }

        .sidebar--right__bg {
            visibility: visible;
        }
    }

    .sidebar--right__bg {
        position: absolute;
        z-index: 5;
        top: -70px;
        left: -500%;
        // Since the element (sidebar--right__bg) is tied to the sidebar, we need to expand beyond it, we're given an arbitrary large width and left positioning so that it may take the width completely
        width: 1000%;
        height: 110%;
        background-color: transparent;
        visibility: hidden;
    }

    .sidebar-right__table {
        display: table;

        > div:not(.sidebar-collapse__container) {
            display: table-cell;
            vertical-align: top;

            &:last-child {
                .channel-header__icon {
                    margin-right: 12px;
                }
            }
        }

        .search-form__container {
            width: 100%;
        }
    }

    .channel-archived-warning {
        padding: 20px;
    }

    .sidebar--right__content {
        @include display-flex;
        @include flex-direction(column);

        height: 100%;
    }

    .sidebar--right__back {
        @include single-transition(all, 0.2s, ease-in);

        display: flex;
        width: 2.4rem;
        height: 2.4rem;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        border-radius: 4px;
        color: rgba(var(--center-channel-color-rgb), 0.56);
        font-size: 12px;
        text-decoration: none;

        &:hover {
            background-color: rgba(var(--center-channel-color-rgb), 0.08);
            color: rgba(var(--center-channel-color-rgb), 0.72);
        }

        &:active {
            background-color: rgba(var(--button-bg-rgb), 0.08);
            color: v(button-bg);
        }
    }

    .sidebar-right__body {
        @include display-flex;
        @include flex(1 1 auto);
        @include flex-direction(column);

        height: calc(100% - 56px);
        border-left: 1px solid rgba(var(--center-channel-color-rgb), 0.12);
        background: v(center-channel-bg);

        .loading-screen {
            position: relative;
            height: 40px;
            padding: 0;

            .loading__content {
                height: 40px;
            }
        }

        .info-card {
            padding: 5px 15px 60px 15px;
        }

        .scrollbar--view {
            .emoji-picker--active & {
                pointer-events: none;
            }
        }
    }

    .sidebar__overlay {
        @include opacity(0.1);

        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: $yellow;
        pointer-events: none;
    }

    .input-group {
        word-break: break-word;
    }

    .sidebar--right__buttons {
        float: right;
    }

    .sidebar--right__expand {
        margin: 0 4px 0 0;

        i {
            top: 0;
        }

        .icon-arrow-collapse {
            display: none;
        }
    }

    .sidebar--right__title {
        display: flex;
        height: 2.4rem;
        flex: 1 1 auto;
        padding: 0 1.6rem;
        font-family: Metropolis, sans-serif;
        font-size: 1.6rem;
        font-weight: 600;

        @include clearfix;
    }

    .sidebar--right__title__channel {
        overflow: hidden;
        height: 2.4rem;
        padding: 0 8px;
        border-left: 1px solid rgba(var(--center-channel-color-rgb), 0.16);
        margin: 0 0 0 8px;
        color: rgba(var(--center-channel-color-rgb), 0.56);
        cursor: pointer;
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: normal;
        line-height: 2.4rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            border-color: transparent;
            background: rgba(var(--center-channel-color-rgb), 0.08);
            border-radius: 4px;
            color: rgba(var(--center-channel-color-rgb), 0.72);
        }

        &:active {
            background: rgba(var(--button-bg-rgb), 0.08);
            color: v(button-bg);
        }
    }

    .sidebar--right__follow__thread {
        height: 2.4rem;
        margin-right: 4px;
        font-size: 12px;
        line-height: 16px;
    }

    .sidebar--right__header {
        @include flex(0 0 56px);

        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 0;
        border-bottom: 1px solid rgba(var(--center-channel-color-rgb), 0.08);
        background: rgba(var(--center-channel-color-rgb), 0.04);
        color: inherit;
        white-space: nowrap;

        .btn-icon {
            font-size: 20px;
        }

        .controls {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .sidebar--right__loading {
        @include opacity(0.7);

        text-align: center;

        .fa {
            margin-right: 5px;
        }
    }

    .sidebar--right__subheader {
        @include single-transition(width, 0.25s, ease-in);

        width: 100%;
        padding-bottom: 10vh;
        margin: auto;
        font-size: 1em;

        &.search__hints {
            margin: 0;
        }

        h4 {
            margin: 0 0 16px;
            font-size: 1em;
        }

        ul {
            @include opacity(0.7);

            padding: 0 0 0 30px;
            margin: 0;
        }

        li {
            padding-bottom: 10px;
            font-size: 0.95em;
        }

        .usage__icon {
            @include opacity(0.9);

            position: relative;
            top: 3px;
            margin: 0 3px;
        }

        &.sidebar-expanded {
            width: 52%;
        }
    }

    .suggestion-list__content {
        max-height: 120px;
    }
}

.sidebar-right-container {
    height: calc(100% - 63px);
}

.sidebar-collapse__container {
    display: none;
}

.sidebar-collapse {
    @include single-transition(all, 0.2s, linear);
    @include translateX(0);

    z-index: 5;
    display: flex;
    width: 45px;
    height: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;

    .fa {
        position: relative;
        top: 1px;
    }
}
