@charset 'UTF-8';

.dropdown-menu {
    @include border-radius(4px);

    &.colorpicker {
        z-index: 2500;
        min-width: 125px;
    }

    .dropdown-item__plugin {
        display: flex;
        justify-content: center;

        &:empty {
            display: none;
        }
    }

    > li {
        > a {
            color: inherit;

            &:hover {
                color: inherit;
            }
        }
    }

    .call-plugin-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        color: rgba(var(--center-channel-color-rgb), 0.56);
        fill: rgba(var(--center-channel-color-rgb), 0.56);
        font-size: 1.6rem;
        text-align: center;
        vertical-align: middle;

        svg,
        img,
        .icon {
            width: 16px;
            height: 16px;
        }
    }

    .a11y--focused {
        box-shadow: none !important;
    }
}

.open {
    > .dropdown-menu__content {
        display: block;

        > .dropdown-menu {
            display: block;
        }
    }
}

.dropdown-menu__content {
    display: none;
}

.dropdown-menu__icons {
    li {
        .fa {
            width: 25px;
            margin-left: -5px;
            text-align: center;
        }
    }
}
