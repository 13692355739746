.team-sidebar {
    position: fixed;
    z-index: 17;
    left: 0;
    display: flex;
    width: 65px;
    height: 100%;
    flex-direction: column;
    background: var(--sidebar-teambar-bg);
    text-align: center;

    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    .fa {
        @include single-transition(all, 0.15s, ease);
        @include opacity(0.6);

        color: var(--sidebar-header-text-color);

        &:hover {
            @include opacity(1);
        }
    }

    .team-sidebar-bottom-plugin {
        width: 100%;
        flex: 0 0 auto;
        padding: 16px 0 6px;
        background-color: var(--sidebar-teambar-bg);
    }

    .team-wrapper {
        position: relative;
        overflow: hidden;
        height: 100%;
        flex: 1 1 auto;
        background-color: var(--sidebar-teambar-bg);
        -webkit-overflow-scrolling: touch;

        .draggable-team-container {
            position: relative;
            margin-right: auto;
            margin-bottom: 12px;
            margin-left: auto;
        }

        .team-container {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &.unread {
                .unread-badge {
                    position: absolute;
                    z-index: 1;
                    top: -2px;
                    right: -3px;
                    width: 8px;
                    height: 8px;
                    background: var(--mention-bg);
                    border-radius: 100%;
                    box-shadow: 0 0 0 3px var(--sidebar-teambar-bg);
                }
            }

            a {
                display: block;
                text-decoration: none;
                transform: translate(0, 0);
                transform-origin: center center;
                transition: transform 0.1s;

                &:active {
                    transform: translate(0, 2px);
                }
            }

            &.active {
                .TeamIcon__content {
                    @include opacity(1);
                }
            }

            button {
                padding: 0;
                border: none;
                text-decoration: none;
            }

            a.team-disabled {
                @include opacity(0.5);

                cursor: not-allowed;
            }

            .team-btn {
                color: var(--sidebar-header-text-color);

                .badge {
                    border-color: rgba(var(--sidebar-text-rgb), 0.5);
                }
            }

            .order-indicator {
                position: absolute;
                top: 28px;
                left: 12px;
                display: flex;
                width: 16px;
                height: 16px;
                justify-content: center;
                background: #000;
                border-radius: 4px;
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                text-align: center;
            }

            &.isDragging {
                cursor: grab;

                .TeamIcon {
                    background-color: rgba(255, 255, 255, 0.32);

                    .TeamIcon__image {
                        box-shadow: inset 0 0 0 3px var(--sidebar-teambar-bg);
                    }
                }
            }
        }

        .scrollbar--view {
            padding-top: 15px;
        }

        .team-container a:hover {
            text-decoration: none;
        }
    }
}

.multi-teams {
    .sidebar--left {
        left: 65px;
    }

    .app__content {
        margin-left: 285px;
    }
}
